import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import getMonth from "../utils/getMonth"

const Post = styled.div`
  transition: all 0.2s;
  border-radius: 5px;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  background-color: white;
  text-align: left;
  cursor: pointer;
  &:hover {
    transform: translate(0, -10px);
  }
  .white-background {
    h5 {
      height: 70px;
      margin: 0;
      font-size: 22px;
      padding: 20px 20px 0;
      margin-bottom: 10px;
      -webkit-line-clamp: 3;
      font-family: "Merriweather", Georgia, Serif;
      overflow-y: hidden;
    }
    .summary {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      color: #73737d;
      padding: 0 20px;
      font-family: -apple-system, BlinkMacSystemFont, "San Francisco",
        "Helvetica Neue", Helvetica, Ubuntu, Roboto, Noto, "Segoe UI", Arial,
        sans-serif;
    }
    .date {
      padding: 0 20px;
      font-weight: 600;
      font-size: 16px;
      color: #73737d;
      opacity: 0.33;
    }
    max-width: 350px;
  }
`

const BlogRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 0 6rem 0;
  a {
    text-decoration: none;
    color: unset;
    margin: 20px;
    >div {
      height: 100%;
      padding-bottom: 0.6rem;
    }
  }
  @media only screen and (max-width: 1250px) {
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    padding: 0 0 3rem 0;
  }
`

const Blog = ({ allContentfulBlog }) => {
  console.log(allContentfulBlog.nodes);
  return (
    <BlogRow jc="space-between" pa="0 0 10rem 0">
      {allContentfulBlog.nodes.map((post, i) => {
        const date = new Date(post.time)
        if(post.image){
          return (
            <Link key={post.slug} to={`/blog/${post.slug}`}>
              <Post key={post.contentful_id}>
                <Img fixed={post.image.fixed}></Img>
                <div className="white-background">
                  <h5>{post.header}</h5>
                  <p className="summary">{post.shortText}</p>
                  <p className="date">{`${getMonth(
                    date.getMonth()
                  )} ${date.getDate()}, ${date.getFullYear()}`}</p>
                </div>
              </Post>
            </Link>
          )
        }
        return <></>
      })}
    </BlogRow>
  )
}

export default Blog
