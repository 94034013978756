import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"

const Wrapper = styled.div`
  margin-top: 41.75px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 50px;
  grid-auto-columns: 1fr;
  @media only screen and (max-width: 980px) {
    display: block;
  }
  .whole-review-container {
    padding-bottom: 40px;
    text-align: left;
    position: relative;
    .review-container {
      &:before {
        color: rgb(187, 187, 187);
        content: "“";
        left: 55px;
        position: absolute;
        font: normal normal normal normal 60px / 96px Georgia, sans-serif;
        margin: -25px 0px 0px -40px;
      }
      position: relative;
      height: calc(100% - 129px);
      box-shadow: rgba(0, 0, 0, 12%) 2px 2px 5px 2px;
      outline: rgb(255, 255, 255) none 0px;
      padding: 20px 20px 30px 48px;
      background-color: #f5f2e7;
      margin: 0px 0px 25px;
      p {
        margin: 0;
        font: italic normal normal normal 14px / 22.4px "Helvetica Neue",
          Helvetica, Arial, sans-serif;
        color: rgb(119, 119, 119);
        span {
          margin-right: 5px;
        }
      }
      &:after {
        bottom: -30px;
        box-sizing: border-box;
        content: "";
        left: 80px;
        position: absolute;
        filter: drop-shadow(rgba(0, 0, 0, 0.0980392) 2px 2px 1px);
        border-top: 30px solid #f5f2e7;
        border-right: 30px solid rgba(0, 0, 0, 0);
        border-bottom: 0px solid rgba(0, 0, 0, 0);
        border-left: 0px solid rgba(0, 0, 0, 0);
        font: normal normal normal normal 13px / 20.8px "Helvetica Neue",
          Helvetica, Arial, sans-serif;
      }
    }
    img {
      border-radius: 25px;
    }
    .logoContainer {
      position: absolute;
      right: 4px;
      bottom: 4px;
    }
  }
`

const Review = () => {
  const { stars, logo, reviews } = useStaticQuery(graphql`
    query {
      stars: file(relativePath: { eq: "tripadvisor_stars_5.png" }) {
        childImageSharp {
          fixed(width: 100, height: 22) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      logo: file(relativePath: { eq: "tripadvisor_outline.png" }) {
        childImageSharp {
          fixed(width: 47, height: 29) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      reviews: allContentfulReview {
        nodes {
          contentful_id
          header
          person
          review {
            review
          }
          image {
            fixed(width: 50, height: 50) {
              ...GatsbyContentfulFixed_withWebp
            }
          }
        }
      }
    }
  `)
  return (
    <Wrapper>
      {reviews.nodes.map((x: any) => (
        <div className="whole-review-container" key={x.contentful_id}>
          <div className="review-container">
            <span>
              <Img fixed={stars.childImageSharp.fixed}></Img>
              <p>{x.review.review}</p>
            </span>
            <div className="logoContainer">
              <Img fixed={logo.childImageSharp.fixed}></Img>
            </div>
          </div>
          <Img fixed={x.image.fixed}></Img>
        </div>
      ))}
    </Wrapper>
  )
}

export default Review
