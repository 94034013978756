import React from "react"
import { TextCenter, Row } from "./General"
import { SectionHeader, SubSectionHeader } from "./Headings 2"
import { Link, useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { ActionButton } from "../components/buttons"
import EnquireButton from "../components/EnquireButton"
import TextSectionBackground from "./TextSectionBackground"
import Img from "gatsby-image"
import Review from "../components/Review"
import Blog from "../components/Blog"

const ActivityWrapper = styled.div`
  maxWidth: 650px; 
  marginBottom: 60px;
  @media all and (max-width: 980px) {
    margin-bottom: 30px;
  }
`

const ActivityHeader = styled.h6`
  font-family: "Playfair Display", Georgia, "Times New Roman", serif;
  font-weight: 500;
  color: #3c3c3c;
  font-size: 18px;
  margin: 20px 0;
  word-break: break-word;
`

const ActivityDescription = styled.p`
  font-family: "Playfair Display", Georgia, "Times New Roman", serif;
  font-weight: 500;
  font-size: 14px;
  color: #666;
  font-weight: 500;
`

const SectionDescription = styled.p`
  font-family: "Playfair Display", Georgia, "Times New Roman", serif;
  font-weight: 500;
  font-size: 14px;
  color: #666;
  font-weight: 500;
  column-count: 2;
  column-gap: 36px;
  text-align: left;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 100px;
  grid-auto-rows: 200px;
  margin-top: 30px;
  margin-bottom: 50px;
  @media only screen and (max-width: 980px) {
    display: block;
    margin-bottom: 20px;
  }
`
const Inner = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  
`

const ButtonWrapper = styled.div`
  text-align: center;
  margin: 2rem 0;
  button {
    margin: 8px 10px;
  }
`

const ActivitySection = ({ img, header, description, link }: any) => {
  return (
    <Row fd="column" ai="center">
      <ActivityWrapper>
        {link.includes("https") ? (
          <a
            href="https://www.google.com/maps/place/Mavela+Game+Lodge/@-27.713205,32.0153503,17z/data=!3m1!4b1!4m8!3m7!1s0x1efaca3c017a91b3:0xa86152d5e4bc09b0!5m2!4m1!1i2!8m2!3d-27.713205!4d32.017539"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Img fixed={img} />
          </a>
        ) : (
          <Link to={link}>
            <Img fixed={img} />
          </Link>
        )}
        <ActivityHeader>{header}</ActivityHeader>
        <ActivityDescription>{description}</ActivityDescription>
      </ActivityWrapper>
    </Row>
  )
}

const TextSection1 = ({ images }: any) => {
  return (
    <TextSectionBackground id="ts1">
      <Inner>
        <TextCenter pa="1rem 0">
          <SectionHeader>Experience a True African Wildlife Safari Escape</SectionHeader>
        </TextCenter>
        <Grid>
          <ActivitySection
            img={images.nodes[5].childImageSharp.fixed}
            header="LUXURY SAFARI-STYLE TENTED CAMP"
            description="Let your senses blend into nature when you stay in our luxury tented accommodation. Mavela Game Lodge hosts seven luxury, en suite safari-style tents each designed for a truly luxurious safari escape with a private deck looking over the surrounding bushveld."
            link="/gallery#tents"
          />
          <ActivitySection
            img={images.nodes[1].childImageSharp.fixed}
            header="ENJOY ALL-INCLUSIVE MEALS & DRINKS"
            description="Enjoy early morning coffee and rusks before your morning game drive, a full English and continental breakfast to settle you into an afternoon siesta. You’ll then be served a light lunch before your afternoon game drive which welcomes you back to a 3-course dinner, it’s a feast for your soul. Included in your rate are all alcoholic drinks and good quality wines."
            link="/gallery#meals"
          />
          <ActivitySection
            img={images.nodes[2].childImageSharp.fixed}
            header="A PRIME SAFARI LOCATION"
            description="Mavela Game Lodge is located in the heart of the Big Five KwaZulu-Natal Wilderness. Situated overlooking the magnificent scenery in the 23,000 hectares of the Manyoni Private Game Reserve, in the Mkuze Valley Lowveld of Northern KwaZulu-Natal, South Africa."
            link="https://www.google.com/maps/place/Mavela+Game+Lodge/@-27.713205,32.0153503,17z/data=!3m1!4b1!4m8!3m7!1s0x1efaca3c017a91b3:0xa86152d5e4bc09b0!5m2!4m1!1i2!8m2!3d-27.713205!4d32.017539"
            target="_blank"
          />
          <ActivitySection
            img={images.nodes[4].childImageSharp.fixed}
            header="GAME DRIVE THE BIG 5 & INCREDIBLE WILDLIFE"
            description="The Manyoni Private Game Reserve is home to over 70 mammal species including Cheetah, Hyena, Giraffe, Kudu, Nyala, Wildebeest, Zebra and the Big 5 (Lion, Leopard, Buffalo, Elephant & Black & White Rhino)."
            link="/gallery#big5"
          />
          <ActivitySection
            img={images.nodes[0].childImageSharp.fixed}
            header="A BIRD WATCHING PARADISE"
            description="The Manyoni Private Game Reserve is renowned for its exceptional birding opportunities. Tick off your lifer list with over 420 bird species that have been recorded on the property. Whether you’re on a game drive or watching from your private deck, it’s a birders bucket list."
            link="/gallery#birds"
          />
          <ActivitySection
            img={images.nodes[3].childImageSharp.fixed}
            header="THE MAIN LODGE"
            description="Mavela Game Lodge is an open-plan, two-story thatched welcome area with lounging spots, an upstairs “ help yourself” bar and spacious dining facilities. These all lead out to a generous pool and large wooden veranda looking onto a small watering hole and vast views of the African bushveld."
            link="/gallery#lodge"
          />
        </Grid>
      </Inner>      
    </TextSectionBackground>
  )
}

const TextSection2 = () => {
  return (
    <TextSectionBackground>
      <Inner>
        <TextCenter pa="1rem 0">
          <SectionHeader>Luxury Safari-Style Tented Camp</SectionHeader>
          <SubSectionHeader>An authentic safari getaway</SubSectionHeader>
          <SectionDescription>
          Our guests can unwind and make themselves at home in one of our seven, luxury safari-style tents. Each luxury tent is tastefully decorated complete with aircon, standing fans and luxurious, eco-friendly Afri-Earth amenities and crisp white linen on either single or king beds. There is an en-suite bathroom and shower plus an intimate outdoor shower that faces the views of the bushveld. 
          
          Unwind on a large private deck looking out at the beautiful views with comfortable deck chairs and a birdbath in front of each tent, which birding enthusiasts can enjoy ticking off their lifers from the comfort of their veranda. The entire camp is fenced where guests will be escorted back to their rooms after dinner. At Mavela Game Lodge you’ll feel the intimate safari lodge experience in a unique part of the African bush.

          </SectionDescription>
          <ButtonWrapper>
            <EnquireButton />
            <ActionButton ma="20px 0 60px 15px">
              <Link target="_blank" to="https://book.nightsbridge.com/16200">
                Check Availability
              </Link>
            </ActionButton>
          </ButtonWrapper>          
        </TextCenter>
      </Inner>      
    </TextSectionBackground>
  )
}

const TextSection3 = () => {
  return (
    <TextSectionBackground style={{background:"#ABA17B" , color:"white"}}>
      <Inner>
        <TextCenter pa="1rem 0" style={{filter:"contrast(6)"}}>
          <SectionHeader>
            Guest Experiences
          </SectionHeader>
        </TextCenter>
        <Review></Review>
      </Inner>
          </TextSectionBackground>
  )
}

const TextSection4 = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulBlog(limit: 3, sort: { fields: time, order: DESC }) {
        nodes {
          header
          time
          slug
          shortText
          contentful_id
          image {
            fixed(width: 350, height: 225) {
              ...GatsbyContentfulFixed_withWebp
            }
          }
        }
      }
    }
  `)
  return (
    <TextSectionBackground>
      <TextCenter>
        <SectionHeader>Latest News</SectionHeader>
        <SubSectionHeader>Whats been happening at Mavela</SubSectionHeader>
        <Row fw="wrap"></Row>
      </TextCenter>
      <Blog allContentfulBlog={data.allContentfulBlog}></Blog>
    </TextSectionBackground>
  )
}

export { TextSection1, TextSection2, TextSection3, TextSection4 }
